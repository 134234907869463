<!--  -->
<template>
  <div class='kecheng'>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="专业课程(报名缴费)" name="1">
        <template v-if="buy">
          <div class="year">
            <span>选择年份</span>
            <el-select v-model="year" placeholder="请选择" @change="changeYear" class="picker">
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </div>
          <!-- <div class="list">
            <div v-for="(item, index) in list" :key="index">
              <img :src="item.image" alt="">
              <div>
                <h4>{{item.keshi_name}}</h4>
                <span>授课：{{item.zjr}}</span>
                <span>时长：{{item.shichang | setTime}}分钟</span>
                <span>单位：{{item.danwei}}</span>
                <el-button round plain style="width: 120px" size="mini" type="success" @click="handleItem">添加</el-button>
              </div>
            </div>
          </div> -->

          <div class="year-list">
            <div v-for="(item, index) in list" :key="index">
              <div>
                <div class="content">
                  <h2>{{item.year}}年{{item.leixing}}</h2>
                  <div>继续教育专业科目</div>
                  <span>课时{{item.keshi}}学时</span>
                </div>
                <div>
                  <span>学习进度：{{item.jindu}}%</span>
                  <el-progress :percentage="item.jindu" color="#06B487" :show-text="false" :stroke-width="10" style="width: 180px;"></el-progress>
                </div>
              </div>
              <div>
                <!-- <el-button round type="success" plain class="self-button--success" size="mini" @click="handleExam(item.year)">药师考试</el-button> -->
                <el-button round type="success" class="self-button--success" size="mini" @click="handleStudy(item.year)">开始学习</el-button>
              </div>
            </div>
          </div>
        </template>
    
        <Gouke v-else @hide="handleHide" :isComponents="true"></Gouke>
      </el-tab-pane>
      
      <el-tab-pane label="公需课程(免费报名)" name="2">
        <div class="year">
          <span>选择年份</span>
          <el-select v-model="year" placeholder="请选择" @change="onChangeYear" class="picker">
            <el-option
              v-for="item in options"
              :key="item"
              :label="String(item)"
              :value="item">
            </el-option>
          </el-select>
        </div>
        <span class="hint">公需课程包：请选择其中一个课程“确认报名”。</span>
        <div class="gx-list">
          <div v-for="(item, index) in list" :key="index">
            <div>
              <div class="content">
                <h2>{{year}}年公需科目</h2>
                <div>{{item.group_name}}</div>
              </div>
            </div>
            <div>
              <el-button round type="success" class="self-button--success" size="mini" @click="handleApply(index)">确认报名</el-button>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Api from '../api/study'
import GApi from '../api/gongxu'
import MApi from '../api/my'
import Gouke from './gouke.vue'

export default {
  components: {
    Gouke
  },
  data() {
    return {
      year: '',
      year_copy: '',
      options: [],
      list: [],
      buy: true,
      activeName: '1'
    };
  },
  filters: {
    setTime (value) {
      return Math.round(value/60)
    }
  },
  computed: {},
  watch: {},
  created() {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    this.options = userInfo.years
    this.year = localStorage.getItem("year")
    this.getCourseList()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    handleClick () {
      if (this.activeName == '1') {
        let userInfo = localStorage.getItem('userInfo')
        userInfo = JSON.parse(userInfo)
        this.options = userInfo.years
        this.year = localStorage.getItem("year")
        this.getCourseList()
      } else {
        this.getYears()
      }
    },
    getCourseList () {
      Api.getCourseList().then(res => {
        if (res.status == 200) {
          // this.list = res.data
          if (res.data.length == 0) {
            this.buy = false
          } else {
            this.buy = true
            this.getFapiaoArr()
          }
        } else {
          this.$message.error('课程列表获取失败');
        }
      })
    },
    getFapiaoArr () {
      MApi.getFapiaoArr({
        type: 2
      }).then(res => {
        if (res.data.code == 1) {
          let list = res.data.res
          for (let i in list) {
            if (list[i].year == this.year) {
              this.list = [list[i]]
            }
          }
        } else {
          this.$message.error("课程列表获取失败")
        }
      })
    },
    handleStudy (year) {
      localStorage.setItem("year", year)
      this.setUserInfo()
      this.$router.push({name: 'Xuexi'})
    },
    changeYear () {
      localStorage.setItem("year", this.year)
      this.setUserInfo()
      this.getCourseList()
    },
    handleItem () {
      this.$message.success({
        message: "您已选"+this.year+"年课程，请在“我的课程”中查看",
        customClass: 'toast'
      })
    },
    getYears () {
      GApi.getYears().then(res => {
        if (res.data.code == 1) {
          this.options =  res.data.data.years
          this.year = res.data.data.year
          this.year_copy = this.year
          if (this.year == 2020) {
            this.$alert('2020年公需科目无课程学习，广东省执业药师注册从2021年开始提供公需科目。', '温馨提示', {
              confirmButtonText: '确认',
              callback: () => {
                this.$router.back()
              }
            });
            return;
          }
          this.getCourseArr()
        } else {
          this.$message.error('公需科目年份目录获取失败')
        }
      })
    },
    onChangeYear () {
      GApi.setYears({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.year_copy = this.year
          this.setUserInfo()
          this.getCourseArr()
        } else {
          this.year = this.year_copy
          this.$message.error("公需科目年份切换失败")
        }
      })
    },
    getCourseArr () {
      GApi.getCourseList({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          if (res.data.data.length > 0) {
            this.$router.replace({name: 'Gongxu'})
          } else {
            this.getBagList()
          }
        } else {
          this.$message.error("公需科目课程列表获取失败")
        }
      })
    },
    getBagList () {
      GApi.getBagList({
        year: this.year
      }).then(res => {
        if (res.data.code == 1) {
          this.list = res.data.data
        } else{
          this.$message.error('公需科目课程包获取失败')
        }
      })
    },
    handleHide () {
      this.buy = true
      this.year = localStorage.getItem("year")
      this.getCourseList()
    },
    handleApply (index) {
      this.$router.push({name: 'Affirm', query: {index: index, year: this.year}})
    },
  },
}
</script>

<style lang='less' scoped>
.kecheng {
  padding: 20px;
  background-color: #fff;
  /deep/ .el-tabs__item {
    font-size: 18px;
    font-weight: bold;
  }
  .year {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    > span {
      margin-right: 15px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .list {
    padding: 30px 0 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 420px;
      margin-bottom: 40px;
      display: flex;
      margin-right: 20px;
      img {
        width: 150px;
      }
      > div {
        margin-left: 15px;
        h4,span {
          display: block;
          margin-bottom: 10px;
        }
        span {
          color: #999;
        }
      }
    }
  }

  .year-list {
    padding: 10px 20px 30px;
    border-top: 1px solid #f3f3f3;
    > div {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > div:first-child {
        display: flex;
        align-items: flex-end;
        > div:last-child {
          span {
            display: inline-block;
            margin-bottom: 10px;
            margin-right: 10px;
          }
        }
      }
      .content {
        margin-right: 20px;
        background-image: url("../assets/img/beij.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 360px;
        height: 220px;
        padding: 0 35px;
        h2, > div {
          color: @default;
          margin-bottom: 15px;
        }
        > div {
          font-size: 22px;
          white-space: wrap;
          word-break: break-all;
          text-align: center;
        }
        span {
          width: 40%;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          text-align: center;
          background-color: @default;
          color: #f3f3f3;
          font-size: 14px;
        }
      }
    }
  }

  .hint {
    color: red;
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  .gx-list {
    padding: 10px 20px 30px;
    border-top: 1px solid #f3f3f3;
    > div {
      padding: 20px 0;
      border-bottom: 1px solid #f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .content {
        margin-right: 20px;
        background-image: url("../assets/img/beij.png");
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        width: 360px;
        height: 220px;
        padding: 0 35px;
        h2, > div {
          color: @default;
          margin-bottom: 15px;
        }
        > div {
          font-size: 22px;
          white-space: wrap;
          word-break: break-all;
          text-align: center;
        }
        span {
          width: 40%;
          height: 25px;
          line-height: 25px;
          border-radius: 12px;
          text-align: center;
          background-color: @default;
          color: #f3f3f3;
          font-size: 14px;
        }
      }
      > div:last-child {
        width: 32%;
      }
    }
  }
}
</style>