import request from '../utils/request'

export default {
  getYears: (data) => {
    return request({
      url: 'Home/Apigx/get_years',
      method: 'post',
      data: data
    })
  },
  setYears: (data) => {
    return request({
      url: 'Home/Apigx/set_year',
      method: 'post',
      data: data
    })
  },
  getStatus: (data) => {
    return request({
      url: 'Home/Apigx/mykc_num',
      method: 'post',
      data: data
    })
  },
  getCourseList: (data) => {
    return request({
      url: 'Home/Apigx/get_gx_kecheng',
      method: 'post',
      data: data
    })
  },
  getBagList: (data) => {
    return request({
      url: 'Home/Apigx/gxkc_xk_list',
      method: 'post',
      data: data
    })
  },
  handleBuy: (data) => {
    return request({
      url: 'Home/Apigx/gxkc_xk_operation',
      method: 'post',
      data: data
    })
  },
  getVideo: (data) => {
    return request({
      url: 'Home/Apigx/check_tuodong',
      method: 'post',
      data: data
    })
  },
  coursejilu: (data) => {
    return request({
      url: 'Home/Apigx/synchro_coursejilu',
      method: 'post',
      data: data
    })
  },
  savePlayPlan: (data) => {
    return request({
      url: 'Home/Apigx/jindu',
      method: 'post',
      data: data
    })
  },
  playOver: (data) => {
    return request({
      url: 'Home/Apigx/wanbi',
      method: 'post',
      data: data
    })
  },
  getTopic: (data) => {
    return request({
      url: 'Home/Apigx/get_tanti',
      method: 'post',
      data: data
    })
  },
  datiOver: (data) => {
    return request({
      url: 'Home/Apigx/huida_tanti',
      method: 'post',
      data: data
    })
  },
  getAdmin: (data) => {
    return request({
      url: 'Home/Apigx/check_bofang',
      method: 'post',
      data: data
    })
  },
}
